<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'profile' }">Profile</router-link>
                </p>
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 mt-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="2" class="mb-6">
                        <v-app-bar :color="primaryColor" dark flat dense>
                            <v-app-bar-title>My roles and permissions</v-app-bar-title>
                            <v-spacer/>
                            <v-btn icon @click="refreshUser">
                                <font-awesome-icon :icon="['far', 'sync-alt']" fixed-width/>
                            </v-btn>
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0">Roles</p>
                            <ul>
                                <template v-for="role in roleList">
                                    <li :key="role">
                                        <span v-if="roleText[role]">{{ roleText[role] }}</span>
                                        <span v-if="!roleText[role]">{{ role }}</span>
                                    </li>
                                </template>
                                <template v-if="roleList.length === 0">
                                    <li>No assigned roles</li>
                                </template>
                            </ul>
                        </v-card-text>
                    </v-card>
                    <v-expansion-panels class="mt-8" v-ifdev>
                        <v-expansion-panel>
                            <v-expansion-panel-header>
                                <span>Permit Info <font-awesome-icon :icon="['far', 'code']" class="grey--text"/></span>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <pre>{{ JSON.stringify(this.user.permit, null, 2) }}</pre>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';
// import { client } from '@/client';
// import { toText } from '@/sdk/time';

export default {
    data: () => ({
        roleText: {
            'service-admin': 'Service Administrator',
        },
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        permit() {
            return this.user.permit;
        },
        roleList() {
            return this.user.permit?.role ?? [];
        },
    },
    watch: {
        focus() {
            // this.refreshUser(); // App.vue already calls refresh which is loadSession and loadUser every time window gains focus
        },
    },
    methods: {
        async refreshUser() {
            try {
                this.$store.commit('loading', { refreshUser: true });
                await this.$store.dispatch('loadUser');
            } catch (err) {
                console.error('failed to load user', err);
            } finally {
                this.$store.commit('loading', { refreshUser: false });
            }
        },
    },
    mounted() {
        this.refreshUser();
    },
};
</script>
